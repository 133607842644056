<template>
	<div class="farm">
		<v-container>
			<h4 align="center">{{ farm_name }}</h4>
			<v-row align="center" justify="center">
				<!-- <p>เชียงใหม่ฟาร์ม</p>  -->
				<v-btn
					icon
					elevation="2"
					outlined
					class="ma-2"
					@click="$router.push('farmdetail')"
				>
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
				<h1>{{ green_house_name }}</h1>
				<br />
			</v-row>
		</v-container>

		<v-row no-gutters>
			<v-col md="10" offset-md="1">
				<h3 class="ml-2">ข้อมูลโต๊ะปลูก</h3>
				<v-row no-gutters>
					<v-col
						v-for="tabledata in table"
						:key="tabledata.table_id"
						sm="4"
						cols="4"
					>
						<v-card
							rounded
							class="pa-1 ma-1"
							outlined
							align="center"
							justify="center"
							@click="table_info(tabledata)"
						>
							<v-img
								:src="img_icon.table_img"
								height="100px"
								width="auto"
								contain
							></v-img>
							{{ tabledata.table_name }}<br />
							<span class="orange--text"
								>{{ tabledata.crop_day }} วัน</span
							>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col md="10" offset-md="1">
				<v-card class="pa-1 ma-1" outlined rounded>
					<v-img
						:src="img_icon.table_img"
						height="60px"
						width="60px"
					></v-img>
					<br />
					<h3>ข้อมูล {{ table_data.table_name }}</h3>
					<br />
					<v-row no-gutters>
						<v-col cols="4">
							<v-card
								class="pa-1 ma-1"
								outlined
								align="center"
								justify="center"
								@click="
									viewstatus('temperature', 'อุณหภูมิอากาศ')
								"
								>อุณหภูมิอากาศ
								<v-img
									:src="img_icon.thermometer"
									height="100px"
									width="auto"
									contain
								></v-img>
								<span>
									{{ table_environment.temperature }} °C
								</span>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card
								class="pa-1 ma-1"
								outlined
								align="center"
								justify="center"
								@click="
									viewstatus(
										'temperature_water',
										'อุณหภูมิน้ำ'
									)
								"
								>อุณหภูมิน้ำ
								<v-img
									:src="img_icon.water"
									height="100px"
									width="auto"
									contain
								></v-img>
								<span>
									{{ table_environment.temperature_water }} °C
								</span>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card
								class="pa-1 ma-1"
								outlined
								align="center"
								justify="center"
								@click="viewstatus('light', 'ความเข้มแสง')"
							>
								ความเข้มแสง
								<v-img
									:src="img_icon.sun"
									height="100px"
									width="auto"
									contain
								></v-img>
								<span> {{ table_environment.light }} lux </span>
							</v-card>
						</v-col>
						<!-- <v-col cols="4">
              <v-card class="ma-1" outlined align="center" justify="center">
                <v-img
                  :src="img_icon.dropper"
                  height="100px"
                  width="auto"
                  contain
                ></v-img>
                {{ tem4 }} ppm
              </v-card>
            </v-col> -->
					</v-row>
					<h3>ข้อมูลการปลูก</h3>
					<br />
					<p class="ml-2" v-if="table_crop.plant_name">
						พืชที่ปลูก : {{ table_crop.plant_name }}
					</p>
					<p class="ml-2" v-if="table_crop.start_date">
						วันที่ปลูก :
						{{ table_crop.start_date | formatDate }} ({{
							table_crop.crop_day
						}}
						วัน)
					</p>
				</v-card>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col md="10" offset-md="1">
				<v-card class="pa-1 ma-1" outlined rounded>
					<h3>สถานะการไหลของน้ำ</h3>
					<v-row no-gutters class="pa-5">
						<v-col
							v-for="(dd, index) in pipedata.slice(0, 6)"
							:key="dd.io_id"
							cols="4"
						>
							<v-card
								v-if="dd.io_type == 'i'"
								class="pa-1 ma-1"
								outlined
								rounded
								align="center"
								justify="center"
							>
								<!-- class="ripple__container" -->
								<v-img
									:src="img_icon.pipedata_img"
									height="100px"
									width="auto"
									contain
								></v-img>
								รางที่ {{ index + 1 }}<br />
								<span
									v-if="dd.io_open == '1'"
									class="green--text"
									>ไหลปกติ</span
								>
								<span
									v-if="dd.io_open == '0'"
									class="orange--text"
									>หยุดไหล</span
								>
							</v-card>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col md="8" offset-md="2">
							<br />
							<center>
								<v-btn @click="setting" color="cyan">
									ตั้งค่า </v-btn
								><br /><br />
								<v-btn @click="endOfPlanting" color="amber">
									สิ้นสุดการปลูก
								</v-btn>
							</center>
							<br />
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "farm",
	data: () => ({
		farm_name: "",
		green_house_name: "",
		table: [],
		pipedata: [],
		table_environment: {},
		table_crop: {},
		table_data: null,
		img_icon: {
			table_img: require("../assets/hydroponic.png"),
			pipedata_img: require("../assets/hydroponic-gardening.png"),
			thermometer: require("../assets/thermometer.png"),
			water: require("../assets/water.png"),
			sun: require("../assets/sun.png"),
			dropper: require("../assets/dropper.png"),
		},
	}),
	created: function () {
		window.scrollTo(0, 0);
		this.farm_name = this.$session.get("farm_data").farm_name;
		this.green_house_name =
			this.$session.get("green_house_item").green_house_name;
		this.table_list();
		setInterval(() => {
			if (this.$route.name == "farm") {
				this.table_info(this.table_data);
			}
		}, 3000);
	},
	computed: {},

	watch: {},
	methods: {
		table_list() {
			var self = this;

			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_list",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				params: {
					green_house_id:
						this.$session.get("green_house_item").green_house_id,
				},
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						self.table = response.data.data;
						self.table_data = response.data.data[0];
						self.table_info(response.data.data[0]);
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		table_info(table) {
			this.table_data = table;
			var self = this;
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_info",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				params: { table_id: this.table_data.table_id },
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data.data);
					if (response.data.success == 1) {
						if (response.data.data.io.length > 0) {
							self.pipedata = response.data.data.io;
						} else {
							self.pipedata = [];
						}
						if (response.data.data.table_environment.length > 0) {
							self.table_environment =
								response.data.data.table_environment[0];
						} else {
							self.table_environment.active = "";
							self.table_environment.createdate = "";
							self.table_environment.crop_id = "";
							self.table_environment.ec = "";
							self.table_environment.environment_id = "";
							self.table_environment.light = "";
							self.table_environment.table_id = "";
							self.table_environment.temperature = "";
							self.table_environment.temperature_water = "";
						}

						if (response.data.data.table_crop.length > 0) {
							self.table_crop = response.data.data.table_crop[0];
						} else {
							self.table_crop.crop_day = "";
							self.table_crop.plant_id = "";
							self.table_crop.plant_name = "";
							self.table_crop.start_date = "";
							self.table_crop.table_id = "";
							self.table_crop.table_name = "";
						}
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		setting() {
			this.$session.set("table_data", this.table_data);
			this.$router.push("/setting");
		},
		endOfPlanting() {
			var self = this;
			self.$swal
				.fire({
					title: "ยืนยันสิ้นสุดการปลูก",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ใช่",
					cancelButtonText: "ไม่",
				})
				.then((result) => {
					if (result.isConfirmed) {
						var config = {
							method: "post",
							url: "https://cool-agri.com/hydroponic_portal/api/v1/crop",
							headers: {
								Authorization: localStorage.getItem("jwt"),
								"Content-Type": "application/json",
							},
							data: {
								table_id: this.table_data.table_id,
								is_open: 0,
							},
						};
						this.axios(config)
							.then(function (response) {
								console.log(response.data);
								if (response.data.success == 1) {
									self.$swal.fire({
										position: "center",
										icon: "success",
										title: "เรียบร้อย",
										showConfirmButton: false,
										timer: 1500,
									});
								}
							})
							.catch(function (error) {
								console.log(error);
								this.$router.push("/");
							});
					}
				});
		},
		viewstatus(weathereng, weatherthai) {
			this.$session.set("table_data", this.table_data);
			this.$session.set("weather", {
				typeEng: weathereng,
				typeThai: weatherthai,
			});
			this.$router.push("/status");
		},
	},
};
</script>

<style>
.ripple__container {
	opacity: 0.5;
}
.v-card {
	transition: opacity 0.4s ease-in-out;
}
</style>
